import './App.css';
import { NavBar } from './components/NavBar';
import { Slideshow } from './components/slideshow';
import { Reviews } from './components/Reviews';
import { Whatwedo } from './components/whatwedo';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { ServiceCards } from './components/Services';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Info} from './components/infopage';
import { useEffect } from 'react';
import { NotFoundPage } from './components/404';
import { InternalServerErrorPage } from './components/500';


function App() {
  useEffect(() => {
    const sections = document.querySelectorAll('section');
    
    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("visible");
                    observer.unobserve(entry.target);
                }
            });
        },
        { threshold: 0.1 }
    );

    sections.forEach(section => {
        observer.observe(section);
    });
}, []);
  return (
    <div className="App">
      {/* Router wraps all navigable components */}
      <Router>
        {/* NavBar should be outside Routes so it appears on all pages */}
        <NavBar />

        {/* Routes for different pages */}
        <Routes>
          <Route path="/info" element={<Info />} /> {/* Info page */}
          <Route path="/contact" element={<Contact />} /> {/* Info page */}
          <Route path="/reviews" element={<Reviews />} /> {/* Info page */}
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
          <Route path="/500" element={<InternalServerErrorPage />} />
          <Route
            path="/" 
            element={(
              <>
                <Slideshow />
                <Whatwedo />
                <ServiceCards />
                <Reviews />
                <Contact />
              </>
            )}
          />
        </Routes>

        {/* Footer will also appear on all pages */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
