import React from 'react';
import foundation from '../assets/img/noun-foundation-7255769.svg';
import survey from '../assets/img/Group.svg';
import steel from '../assets/img/noun-steel-6268964.svg';
import bricks from '../assets/img/noun-masonry-trowel-2849830.svg';
import plans from '../assets/img/noun-blueprint-82692.svg';
import concrete from '../assets/img/noun-concrete-3091292.svg'

export const services = [
  {
    title: "Structural Surveys/Reports",
    description: "Our qualified structural engineer will conduct an examination of the properties structural integrity, assesing its physical condition and identifying any issues related to the building's framework or materials. A report will then be produced as a result of this survey, providing a detailed description of the findings.",
    icon: survey // Path to the service icon or image
  },
  {
    title: "Structural Alterations",
    description: "Our structural alteration service involves modifying or strengthening a building's framework to accommodate changes such as extensions, wall removals, or load-bearing adjustments. We ensure that all alterations comply with safety regulations, maintaining the structure's integrity while meeting your design requirements. A detailed report will be provided, outlining the process.",
    icon: plans
  },
  {
    title: "Steelwork Design",
    description: "Our steelwork design service provides tailored structural solutions for a variety of projects, ensuring safety, durability, and compliance with industry standards. We work closely with clients to design frameworks that meet load requirements, material specifications, and project timelines. Our engineers deliver detailed plans and calculations to optimise strength and efficiency.",
    icon: steel
  },
  {
    title: "Masonry Design",
    description: "Our structural engineer provides expert masonry design services, ensuring your structure meets safety, performance, and aesthetic standards. We assess load-bearing capacities, material selection, and structural integrity to create designs that blend durability with functionality. From brick to stonework, our designs are tailored to meet the specific needs and conditions of your project.",
    icon: bricks // Path to the service icon or image
  },
  {
    title: "Reinforced Concrete Design",
    description: "Reinforced Concrete Design involves analysing and designing concrete structures strengthened with steel reinforcement to ensure strength, durability, and stability. Our structural engineer calculates load-bearing capacities, bending, shear forces, and material properties to optimize safety. This design method is widely used in buildings, bridges, and infrastructure to resist tensile and compressive forces effectively.",
    icon: concrete
  },
  {
    title: "Foundation Design",
    description: "Foundation design involves determining the appropriate type of foundation based on soil conditions, load-bearing capacity, and building requirements. Our structural engineer ensures the foundation can safely support the structure, prevent settlement, and resist environmental forces. The design considers factors such as depth, materials, and reinforcement methods.",
    icon: foundation
  }
];

export const ServiceCards = () => {
  return (
    <section className="service-cards-section" id='services'>
      <div className="container">
        <h2>Our Services</h2>
        <div className="service-cards">
          {services.map((service, index) => (
            <div className="flip-card" key={index}>
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={service.icon} alt={`${service.title} Icon`} className="service-icon"/>
                  <h3>{service.title}</h3>
                </div>
                <div className="flip-card-back">
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};