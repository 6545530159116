import { Col, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React from 'react';

export const Reviews = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return(
    <section className="review" id="reviews">
            <Row>
                <Col>
                <div className="review-bx">
                    <h2>
                        Reviews
                    </h2>
                    <Carousel responsive={responsive} infinite={true} className="review-slider">
                        <div className="item">
                          <p>I needed a structural engineer to check out some issues with a house. 
                            After a morning ringing round several companies, very few companies even 
                            had anyone available- and if they did, it wasn't for weeks into the future. 
                            Few even bothered to phone me back- but Paul was keen to find out what I needed, 
                            made an appointment to survey the house within a couple of days- then produced the 
                            much-needed report a couple of days later. In the time it took other structural 
                            engineers to even call me back, Paul had done the work and got the report to me. 
                            He was extremely helpful and keen to advise me on exactly what I needed. Very happy customer.
                          </p>
                            <h5>-Gareth Davies</h5>
                        </div>
                        <div className="item">
                            <p>Had a structural survey carried out by Paul on a property we were buying. From 
                              the get go Paul was incredibly friendly, helpful and professional. The survey was 
                              completed in a timely manner and he provided a very clear, concise report. Paul 
                              was more than happy to discuss the report further and explain the findings to us 
                              in a way we could understand, as well as answering further questions we had in the 
                              following weeks. Would highly recommend PF Associates.</p>
                            <h5>-Gabrielle Woodward-Smith</h5>
                        </div>
                        <div className="item">
                            <p>Paul provided us with a structural survey on a property we were looking to purchase. 
                              The service was excellent from start to finish, he promptly arranged to carry out the 
                              survey and the report he produced was very clear and extremely useful- containing clear 
                              explanations of his recommendations and photographs to support his findings. He also 
                              answered a couple of follow up queries we had and gave invaluable advice. I would have 
                              no hesitation in recommending Paul to anyone looking for a structural survey.</p>
                            <h5>-John Gillard</h5>
                        </div>
                    </Carousel>
                </div>
                </Col>
            </Row>
    </section>
    )
}