import { Container, Col, Row } from "react-bootstrap";
import logo from "../assets/img/logo.jpg";
import chartered from '../assets/img/Chartered-Member-logo-print.jpg';
import React from 'react';

export const Footer = () => {
    return(
        <footer className="footer">
            <Container>
                <Row className="align-items-center footer-content">
                    <Col xs={12} className="footer-images text-center">
                    <a href="#home">
                        <img className="footer-logo" src={logo} alt="Logo" />
                        </a>
                        <a href="https://www.istructe.org/" target="_blank" rel="noopener noreferrer">
                        <img className="footer-icon" src={chartered} alt="Institute of structural engineers logo" />
                        </a>
                    </Col>
                    <Col xs={12} className="footer-address text center">
                        <p>PF Associates Ltd: 1 Blackwood Rise, Leeds, LS16 7BG | admin@pfassociates.co.uk | 07902905921</p>
                    </Col>
                </Row>
                <Row className="footer-bottom">
                    <Col sm={12} className="text-center">
                        <p>CopyRight 2024. All rights reserved by Author.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}