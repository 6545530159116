import { useState, useRef } from "react";
import { Col, Row, Container } from "react-bootstrap";
import React from 'react';
import emailjs from '@emailjs/browser';

export const Contact = () => {
    const formInitialDetails = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    }
    const form = useRef();
    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});
    const [errors, setErrors] = useState({});

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        });

        setErrors({
            ...errors,
            [category]: ''
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formDetails.firstName) newErrors.firstName = "First name is required";
        if (!formDetails.lastName) newErrors.lastName = "Last name is required";
        if (!formDetails.email) newErrors.email = "Email is required";
        if (!formDetails.phone) newErrors.phone = "Phone number is required";
        if (!formDetails.message) newErrors.message = "Message is required";
        return newErrors;
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setButtonText("Sending...");
        emailjs
            .sendForm(
                'service_oylpd7p',
                'template_b40r4bw',
                form.current,
                'n2gz7o2zNuf4LhSJm'
            )
            .then(
                () => {
                    setButtonText("Sent");
                    setStatus({ success: true, message: "Message sent successfully!" });
                    setFormDetails(formInitialDetails); // Clear form on success
                    setTimeout(() => setButtonText("Send"), 2000); // Reset button after 2 seconds
                },
                (error) => {
                    setButtonText("Send");
                    setStatus({ success: false, message: "Failed to send message." });
                    console.error("FAILED...", error.text);
                }
            );
    };

    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <h2>Contact us:</h2>
                    <form ref={form} onSubmit={sendEmail}>
                        <Row>
                            <Col sm={6} className="px-1">
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formDetails.firstName}
                                    placeholder="First Name"
                                    onChange={(e) => onFormUpdate('firstName', e.target.value)}
                                />
                                {errors.firstName && <p className="error-text">{errors.firstName}</p>}
                            </Col>
                            <Col sm={6} className="px-1">
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formDetails.lastName}
                                    placeholder="Last Name"
                                    onChange={(e) => onFormUpdate('lastName', e.target.value)}
                                />
                                {errors.lastName && <p className="error-text">{errors.lastName}</p>}
                            </Col>
                            <Col sm={6} className="px-1">
                                <input
                                    type="email"
                                    name="email"
                                    value={formDetails.email}
                                    placeholder="Email"
                                    onChange={(e) => onFormUpdate('email', e.target.value)}
                                />
                                {errors.email && <p className="error-text">{errors.email}</p>}
                            </Col>
                            <Col sm={6} className="px-1">
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formDetails.phone}
                                    placeholder="Phone"
                                    onChange={(e) => onFormUpdate('phone', e.target.value)}
                                />
                                {errors.phone && <p className="error-text">{errors.phone}</p>}
                            </Col>
                            <Col className="px-1 d-flex justify-content-center">
                                <div className="w-100">
                                    <textarea
                                        rows="6"
                                        name="message"
                                        value={formDetails.message}
                                        placeholder="Message"
                                        onChange={(e) => onFormUpdate('message', e.target.value)}
                                    />
                                    {errors.message && <p className="error-text">{errors.message}</p>}
                                </div>
                            </Col>
                            <Col sm={12} className="px-1 text-center">
                                <button type="submit"><span>{buttonText}</span></button>
                                {status.message && (
                                    <p className={status.success ? "success-text" : "error-text"}>
                                        {status.message}
                                    </p>
                                )}
                            </Col>
                            <h3>Or call our team on: 07902905921</h3>
                        </Row>
                    </form>
                </Row>
            </Container>
        </section>
    );
};
