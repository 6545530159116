import React, { useState, useEffect } from 'react';
import slideshow1 from '../assets/img/slideshow1.jpg';
import slideshow2 from '../assets/img/slideshow2.jpg';
import slideshow3 from '../assets/img/slideshow3.jpg';
import slideshow4 from '../assets/img/slideshow4.jpg';
import logo from '../assets/img/smalllogo.jpeg';
import arrow from '../assets/img/arrow.svg'

export const Slideshow = () => {
  const images = [
    slideshow1,
    slideshow2,
    slideshow3,
    slideshow4,
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 2500); // Change slide every 2.5 seconds

    return () => clearInterval(slideInterval); // Cleanup interval on unmount
  }, [images.length]);

  return (
  <section className="slideshow-section" id="home">
    {images.map((image, index) => (
      <div
        key={index}
        className={`slide ${index === currentSlide ? 'active' : ''}`}
        style={{ backgroundImage: `url(${image})` }}
      >
        {/* Central image overlay with text */}
        <div className="central-image">
        <div className="logo-box">
          <img src={logo} alt="Central Logo" />
          <p className="logo-text">PF Associates Ltd<br/ > Structural Engineers</p> {/* Added text */}
          </div>
        </div>
      </div>
    ))}
  
    {/* Arrow button at the bottom */}
    <div className="arrow">
      <ArrowButton />
    </div>
  </section>
  
  );
};

const ArrowButton = () => {
  return (
    <div className="arrow-container" href="#whatwedo">
      <a href="#whatwedo">
      <button className="down-arrow">
      <img src={arrow} alt='arrow'/>
      </button>
      </a>
    </div>
  );
};