import React, { useEffect, useState } from 'react';
import plan from '../assets/img/drawings.jpg';
import beams from '../assets/img/beams.jpg';

const targetCounts = [25, 15, 1]; // Set the target values for each counter
const totalDuration = 3000; // Total duration for all counters to finish in milliseconds

export const Whatwedo = () => {
  const [counters, setCounters] = useState([0, 0, 0]);
  const [hasCounted, setHasCounted] = useState(false);

  useEffect(() => {
    const counterSection = document.getElementById('counter-section');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasCounted) {
            startCounters();
            setHasCounted(true);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (counterSection) {
      observer.observe(counterSection);
    }

    return () => {
      if (counterSection) {
        observer.unobserve(counterSection);
      }
    };
  }, [hasCounted]);

  const startCounters = () => {
    const maxTarget = Math.max(...targetCounts); // Find the maximum target value
    const intervalDuration = totalDuration / maxTarget; // Time per increment
    let currentCounts = Array(targetCounts.length).fill(0); // Initialize counters to 0

    const interval = setInterval(() => {
      let newCounts = [...currentCounts];

      // Update counters based on their target values
      newCounts = newCounts.map((count, index) => {
        const target = targetCounts[index];
        // Calculate how much to increment based on the target and total duration
        const increment = Math.ceil(target / (totalDuration / intervalDuration));
        return Math.min(count + increment, target); // Increment and ensure it doesn't exceed the target
      });

      setCounters(newCounts);
      currentCounts = newCounts; // Update current counts

      // Clear interval when all targets are reached
      if (newCounts.every((count, index) => count === targetCounts[index])) {
        clearInterval(interval);
      }
    }, intervalDuration); // Set the interval based on the calculated timing
  };

  return (
    <section className="structural-engineering-section" id="whatwedo">
      <div className="content-container">
        {/* Text and images side by side */}
        <div className="text-and-images">
          <div className="text-content">
            <h2>Why Choose Us?</h2>
            <p>
              Founded in 2010, PF Associates Ltd provides structural engineering services for its domestic, commercial and industrial clients throughout the UK.
            </p>
            <p>
              We pride ourselves in providing a personable and trustworthy service, delivering cost effective structural solutions that meet our clients' requirements.
            </p>
          </div>
          <div className="image-content">
            <img src={beams} alt="Structural design illustration" className="image" />
            <img src={plan} alt="Engineers working on site" className="image" />
          </div>
        </div>

        {/* Counter Section */}
        <div className="counters-container" id="counter-section">
          <div className="single-counter">
            <span className="counter-number">{counters[0]}+</span>
            <p className="counter-label">Years of Experience</p>
          </div>
          <div className="single-counter">
            <span className="counter-number">{counters[1]}</span>
            <p className="counter-label">Years of establishment</p>
          </div>
          <div className="single-counter">
            <span className="counter-number">{counters[2]}k+</span>
            <p className="counter-label">Satisfied Clients</p>
          </div>
        </div>
      </div>
    </section>
  );
};