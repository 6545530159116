import React from 'react';

export const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <h1 className="error-code">404</h1>
      <p className="error-message">Oops! The page you’re looking for doesn’t exist.</p>
    </div>
  );
};
