import React from 'react';
import logo from '../assets/img/logo.jpg';
import steelwork1 from '../assets/img/steelwork.jpg';
import steelwork2 from '../assets/img/steelwork2.jpg';
import church from '../assets/img/church.jpg';
import kinderhaven from '../assets/img/kinderhaven.jpg';
import commercial from '../assets/img/commercial.jpg';

export const Info = () => {
    const services = [
        {
          title: "Structural Inspection Surveys/Reports",
          description: "Full building survey and report for domestic and commercial property",
          description2: "Specific defect reports",
        },
        {
          title: "Structural Alterations",
          description: "Domestic and commercial, internal and external wall removal",
          description2: "Coomercial frame alterations and additions",
        },
        {
          title: "New Build Properties",
          description: "Foundation design - Traditional/Piled/Raft",
          description2: "Steelwork Design",
          description3: "Timber Design",
          description4: "Masonry Design",
        },
        {
          title: "Extensions & Loft Conversions",
          description: "Foundation design - Traditional/Piled/Raft",
          description2: "Steelwork Design",
          description3: "Timber Design",
          description4: "Masonry Design",
        }
      ];
  return (
    <div className="info-page">
      <section className="hero-section">
        <div className="hero-text">
          <h1>What we do at PF Associates Structural Engineers</h1>
          <p>
            We produce structural engineering designs and structural inspection surveys
            for commercial, residential, and industrial buildings. Our
            commitment to producing quality, personable, and cost effective service drives every
            project we undertake.
          </p>
        </div>
        <div className="hero-image">
          <img src={logo} alt="Engineering Design" />
        </div>
      </section>

      <section className="services-section">
        <h2>Our Services</h2>
        <div className="info-service-cards">
          <div className="info-service-card">
            <h3>{services[0].title}</h3>
            <p>
            <li>{services[0].description}</li>
            <li>{services[0].description2}</li>
            </p>
          </div>
          <div className="info-service-card">
            <h3>{services[1].title}</h3>
            <p>
            <li>{services[1].description}</li>
            <li>{services[1].description2}</li>
            
            </p>
          </div>
          <div className="info-service-card">
            <h3>{services[2].title}</h3>
            <p>
            <li>{services[2].description}</li>
            <li>{services[2].description2}</li>
            <li>{services[2].description3}</li>
            <li>{services[2].description4}</li>
            </p>
          </div>
          <div className="info-service-card">
            <h3>{services[3].title}</h3>
            <p>
            <li>{services[3].description}</li>
            <li>{services[3].description2}</li>
            <li>{services[3].description3}</li>
            <li>{services[3].description4}</li>
            </p>
          </div>
          
        </div>
      </section>

      <section className="projects-section">
        <h2>Our Projects</h2>
        <div className="project-gallery">
            <div className='flip-container'>
            <div className='flipper'>
                <img src={steelwork1} alt="Back Extension Steelwork" className='front'/>
                <img src={steelwork2} alt="Back Extension Steelwork" className='back' />
            </div>
            </div>
            <div className='flip-container'>
            <div className='flipper'>
                <img src={church} alt="church" className='front'/>
                <img src={kinderhaven} alt="kinderhaven" className='back' />
            </div>
            </div>
          <img src={commercial} alt="Commercial" />
        </div>
      </section>

      <section className="contact-section">
        <h2>Contact Us</h2>
        <p>
          If you would like to learn more about our services or request a quote,
          feel free to contact us at:
        </p>
        <p>Email: admin@pfassociates.co.uk</p>
        <p>Phone: 07902905921</p>
      </section>
    </div>
  );
};