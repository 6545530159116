import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/smalllogo.jpeg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import React from 'react';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // For controlling mobile menu visibility
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            setScrolled(window.scrollY > 50);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
        setIsMenuOpen(false);
        setIsClosing(false);
    };

    const toggleMenu = () => {
        if (isMenuOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setIsMenuOpen(false);
                setIsClosing(false);
            }, 500); // Match the duration of the slideUp animation
        } else {
            setIsMenuOpen(true);
        }
    };

    return (
        <>
            {/* Main Navbar */}
            <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
                <Container>
                    <Navbar.Brand href="/">
                        <img className="logo" src={logo} alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
                        <Nav className="me-auto">
                            <Nav.Link href="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                            <Nav.Link href="/info" className={activeLink === 'whatwedo' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('reviews')}>What We Do</Nav.Link>
                            <Nav.Link href="/reviews" className={activeLink === 'reviews' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('reviews')}>Reviews</Nav.Link>
                            <Nav.Link href="/contact" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>Contact Us</Nav.Link>
                        </Nav>
                        <div className="social-icon">
                            <a href='https://www.linkedin.com/company/pf-associates-ltd/' target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="LinkedIn" /></a>
                            <a href='https://www.facebook.com/PFAssociatesLtd?locale=en_GB' target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="Facebook" /></a>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Mobile Dropdown Menu */}
            {(isMenuOpen || isClosing) && (
                <div className={`dropdown-container d-lg-none ${isClosing ? 'closing' : ''}`}>
                    <div className="me-auto navbar-nav left">
                        <Nav.Link href="/" className="dropdown-button" onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                        <Nav.Link href="/info" className="dropdown-button" onClick={() => onUpdateActiveLink('reviews')}>What We Do</Nav.Link>   
                        <Nav.Link href="/reviews" className="dropdown-button" onClick={() => onUpdateActiveLink('reviews')}>Reviews</Nav.Link>
                        <Nav.Link href="/contact" className="dropdown-button" onClick={() => onUpdateActiveLink('contact')}>Contact Us</Nav.Link>
                    </div>
                    <div className="social-icon dropdown-icons">
                        <a href='https://www.linkedin.com/company/pf-associates-ltd/' target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="LinkedIn" /></a>
                        <a href='https://www.facebook.com/PFAssociatesLtd?locale=en_GB' target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="Facebook" /></a>
                    </div>
                </div>
            )}
        </>
    );
};